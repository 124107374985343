/* tslint:disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { globalApi } from 'src/app/globalApi';
import { timeout, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ComandosService {
  uri = globalApi.uri;
  
  extractData(res: Response) {
    let body = res.json();
    return body || {};
  }
  handleErrorPromise(error: Response | any) {
    console.error(error.message || error);
    return Observable.throw(error.message || error);
  }
  public header: any;
  public host: any;
  public token: any;
  public options: any;
  isLoggedin: boolean;
  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('access_token');
    this.header = new HttpHeaders({ 'Content-Type': 'application/json', "method": "post", "Authorization": this.token });
    this.options = { headers: this.header };

   }
   getComandos(): Observable<any> {
    return this.http.post(this.uri + "/comandos", null, this.options).pipe(
      retry(4),
      timeout(15000)
    )
  }

  newComando(data: any): Observable<any> {
    return this.http.post(this.uri + "/createComandos", data, this.options)
  }

  updateComando(data: any): Observable<any> {
    return this.http.post(this.uri + "/updateComando", data, this.options)
  }

  listarComando(data: any): Observable<any> {
    return this.http.post(this.uri + "/listarComando", data, this.options)
  }

  descargarExcel(data: any): Observable<any> {
    return this.http.post(this.uri + "/buscarComando", data, this.options)
  }
  

  getPeriodosComandos(): Observable<any> {
    return this.http.post(this.uri + "/periodosComandos", null, this.options).pipe(
      retry(4),
      timeout(15000)
    )
  }

  updatePeriodosComandos(data: any): Observable<any> {
    
    return this.http.post(this.uri + "/periodosComandosUpdate", data, this.options);
  }

  newPeriodosComandos(data: any): Observable<any> {
    return this.http.post(this.uri + "/createPeriodosComandos", data, this.options)
  }


}
