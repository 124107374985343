import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { timeout, retry } from 'rxjs/operators';
import { globalApi } from 'src/app/globalApi';

@Injectable({
    providedIn: 'root'
})
export class EquipoTarjetasService {
    uri = globalApi.uri;

    extractData(res: Response) {
        let body = res.json();
        return body || {};
    }
    handleErrorPromise(error: Response | any) {
        console.error(error.message || error);
        return Observable.throw(error.message || error);
    }
    public header: any;
    public host: any;
    public token: any;
    public options: any;
    isLoggedin: boolean;

    constructor(private http: HttpClient) {
        this.token = localStorage.getItem('access_token');
        this.header = new HttpHeaders({ "method": "post", "Authorization": this.token });
        this.options = { headers: this.header };

    }

    nuevaTarjeta(data: any): Observable<any> {
        return this.http.post(this.uri + "/nuevaTarjeta", data, this.options)
    }

    editaTarjeta(data: any): Observable<any> {
        return this.http.post(this.uri + "/editaTarjeta", data, this.options)
    }

    getTarjetas(): Observable<any> {
        return this.http.get(this.uri + "/tarjetasMatriz", this.options).pipe(
            retry(4),
            timeout(10000)
        )
    }
    // 
    nuevaRFID(data: any): Observable<any> {
        return this.http.post(this.uri + "/nuevaRFID", data, this.options)
    }

    editaRFID(data: any): Observable<any> {
        return this.http.post(this.uri + "/editaRFID", data, this.options)
    }

    getTarjetasRFID(): Observable<any> {
        return this.http.get(this.uri + "/tarjetasRFID", this.options).pipe(
            retry(4),
            timeout(10000)
        )
    }
    // 
    getTarjetasSinAsignar(): Observable<any> {
        return this.http.get(this.uri + "/rfidSinAsignar", this.options).pipe(
            retry(4),
            timeout(10000)
        )
    }

    getTarjetasAsignadas(): Observable<any> {
        return this.http.get(this.uri + "/rfidAsignadas", this.options).pipe(
            retry(4),
            timeout(10000)
        )
    }


    agregaRfidClientes(data: any): Observable<any> {
        return this.http.post(this.uri + "/agregaRfidClientes", data, this.options)
    }

    rfidClientes(id): Observable<any> {
        return this.http.get(this.uri + "/rfidClientes/" + id, this.options).pipe(
            retry(4),
            timeout(10000)
        )
    }




}